<template>
  <article class="filling">
    <div class="notification__errors" v-if="hasErrors">
      <template v-for="(notification, index) of notifications">
        <p class="filling__message" :key="index" v-if="notification.needRender">
          <span class="filling__icon" v-html="getIcon('attention')"></span>
          <span class="filling__text">{{ notification.text }}</span>
        </p>
      </template>
    </div>
  </article>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'

export default {
  name: 'ErrorNotification',
  props: ['notifications', 'constructionType'],
  mixins: [IconMixin],
  computed: {
    hasErrors() {
      return this.notifications
        ? Object.values(this.notifications).some(notification => notification.needRender)
        : false
    }
  },
  methods: {
    handle(action) {
      this.$emit('handle', { action })
    }
  }
}
</script>

<style scoped lang="sass">
.filling
  width: 100%
  .notification
    &__errors
      text-align: left
      fill: $info
      stroke: $info
      color: $info
    &__has-filling
      display: flex
      flex-flow: row wrap
      align-items: center
      fill: $main-text
      stroke: $main-text
      .filling__message
        align-items: center
        margin: 0
        padding: 0
        font-weight: 400
        line-height: 1.5
        background: none
        fill: $text-gray
        stroke: $text-gray
      .filling__button
        +media((width: (0: 100%, 830: auto)))
        +media((margin: (0: rem(8) 0 0 0, 830: 0 0 0 rem(16))))
  &__message
    display: flex
    gap: rem(12)
    padding: rem(20)
    margin: 0 0 rem(10)
    background: $main-color-with-opacity
    border-radius: $block-radius
  &__text
    color: $main-text
  &__text, &__button .text
    @extend %16
    text-align: left
    line-height: 1.4
  &__button
    display: flex
    align-items: center
    gap: rem(5)
    padding: 0
    margin: rem(30) 0 0
    border: none
    background: none
    cursor: pointer
    .text
      display: inline-block
      padding-top: rem(3)
      font-weight: 600
      color: $main-color
    .icon
      width: rem(18)
      height: rem(18)
  &__icon
    width: rem(24)
    height: rem(24)
  .notification__errors + .notification__has-filling
    margin-top: rem(20)
</style>
