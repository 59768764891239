<template>
  <section class="constructions">
    <h5 class="constructions__title">
      {{ $t('message.constructionParams.constructions.title') }}
    </h5>
    <ul class="constructions__list" ref="grid">
      <li class="constructions__list-item"
          v-for="construction of limitedConstructions"
          :key="construction.title"
      >
        <MediaCart
          :source="construction"
          :verticalGrid="true"
          :active="isSelected(construction)"
          @selectItem="setActiveConstruction($event)"
          @triggerModal="modalEvent($event)"
        >
        </MediaCart>
      </li>
    </ul>
    <div class="constructions__button-wrapper" v-if="constructions.length > initialVisibleItems">
      <simple-button
        color="red" padding="40"
        @btnClicked="allShowed ? minimize() : showMore()"
      >{{ allShowed ? $t('message.buttons.minimize') : $t('message.buttons.showMore') }}</simple-button>
    </div>
  </section>
</template>

<script>
import SimpleButton from '@/components/dump/Simple-button'
import MediaCart from '@/components/dump/MediaCart'
import { initialVisibleItems } from '@/utils/utils'

export default {
  name: 'Constructions',
  components: { MediaCart, SimpleButton },
  props: ['constructions', 'selectedConstructions'],
  data: () => ({
    initialVisibleItems: initialVisibleItems,
    limit: initialVisibleItems,
    allShowed: false
  }),
  computed: {
    limitedConstructions() {
      return this.constructions.filter((item, index) => index < this.limit)
    }
  },
  methods: {
    showMore() {
      this.limit += initialVisibleItems
      if (this.limitedConstructions.length === this.constructions.length) {
        this.allShowed = true
      }
    },
    minimize() {
      this.limit = initialVisibleItems
      this.allShowed = !this.allShowed
    },
    setActiveConstruction(evt) {
      this.$emit('handle', { value: evt })
    },
    isSelected(item) {
      return item.id === this.selectedConstructions?.id
    },
    modalEvent(evt) {
      this.$store.commit('UPDATE_MODAL', {
        property: 'selectedSystem',
        value: evt
      })
    }
  }
}
</script>

<style lang="sass">
.constructions
  width: 100%
  &__title
    font-weight: 600
    line-height: 1.3
    margin: 0 0 rem(20)
  &__list
    display: grid
    grid-gap: rem(20)
    +media((grid-template-columns: (0: 1fr, 530: repeat(2, 1fr), 1070: repeat(3, 1fr))))
    .media-cart
      height: 100%
      border-radius: $block-radius
    .media-cart__wrapper
      min-height: rem(404)
    .media-cart__title
      display: flex
      flex-grow: 0
      min-height: auto
      @extend %16
      font-weight: 600
      margin: rem(26) 0 rem(16)
    .media-cart__image-wrapper
      margin: 0
    .media-cart__text-wrapper
      min-height: calc(100% - 194px)
      max-height: calc(100% - 194px)
      background: $white
  &__list-item
    height: rem(404)
    position: relative
    border-radius: $block-radius
    &:hover
      z-index: 1
      .media-cart
        height: auto
        box-shadow: 0 20px 85px 0 rgba(141, 148, 177, 0.15)
      .media-cart__text-wrapper
        max-height: none
        overflow: visible
      .media-cart__button--details
        display: flex!important
        min-width: rem(180)
  &__button-wrapper
    display: flex
    justify-content: center
    padding: rem(20) 0 0
    .simple-btn
      @extend %16
</style>
